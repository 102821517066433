$spacing: 10px;
$black: #000817;
$white: #fefefe;
$yellow: yellow;

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

@keyframes flash {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(2);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.item {
  width: 100%;
  height: 100%;

  padding: $spacing;
  border-radius: $spacing;
  user-select: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  opacity: 0.6;
  transition: opacity 0.5s, box-shadow 0.5s;
  animation: float 5s ease-in-out infinite;

  color: $white;

  &:hover {
    opacity: 1;
    animation-play-state: paused;
  }

  svg {
    color: $black;
  }

  h1 {
    font-size: 2rem;
    line-height: 1;
    font-weight: 600;
    margin: 0 0 $spacing;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 400;
    margin: 0 0 $spacing;
  }

  header {
    padding-bottom: $spacing;
  }

  article {
    margin: $spacing 0;
    text-align: justify;

    animation: fadeIn 5s;
  }
}

.itemBackground {
  background-color: $yellow;
}

.compass {
  position: fixed;
  width: 6px;
  height: 6px;
  background: radial-gradient(circle, $white 0%, transparentize($yellow, 1) 80%);
  border-radius: 50%;
  pointer-events: none;
  z-index: 999;

  animation: flash 5s ease-in-out infinite, float 5s ease-in-out infinite;
}

.sun {
  border-radius: 50%;
  box-shadow: inset 0 0 10px 30px transparentize($white, 0.8), 0 0 20px 20px transparentize($white, 0.8);

  animation: rotate 220s linear infinite;
}